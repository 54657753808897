import React from "react";

interface DataNavbarProps {
    views: any;
    setSelectedView: any;
}

const DataNavbar: React.FC<DataNavbarProps> = (props) => {
    return (
        <nav className="navbar">
        <ul>
            <li>
                <button
                    onClick={() =>
                        props.setSelectedView(props.views.PIT_SCOUTING)
                    }
                >
                    Pit Scouting
                </button>
            </li>
            <li>
                <button
                    onClick={() =>
                        props.setSelectedView(props.views.MATCH_SCOUTING)
                    }
                >
                    Match Scouting
                </button>
            </li>
            <li>
                <button
                    onClick={() =>
                        props.setSelectedView(
                            props.views.MATCH_SCOUTING_INTERFACE
                        )
                    }
                >
                    Match Scouting Interfaces
                </button>
            </li>
            <li>
                <button
                    onClick={() =>
                        props.setSelectedView(props.views.COMPARISON)
                    }
                >
                    Comparison
                </button>
            </li>
        </ul>
    </nav>
    )
}

export default DataNavbar;