import Entry from "../components/interface/Entry";
import Popup from "../components/Popup";
import DataNavbar from "../components/DataNavbar"
import React, { useEffect, useState } from "react";
import "../styles/DataView.css";

const DataView = () => {
    const views = Object.freeze({
        EVENT_VIEWER: "Event Viewer",
        PIT_SCOUTING: "Pit Scouting",
        MATCH_SCOUTING: "Match Scouting",
        MATCH_SCOUTING_INTERFACE: "MATCH_SCOUTING_INTERFACE",
        COMPARISON: "COMPARISON",
    });

    useEffect(() => {
        fetch("/api/all_competitions")
            .then((response) => response.json())
            .then((data) => {
                setEvents(data.all_competitions.map((competition: any) => competition.name));
            })
            .catch((error) => console.error(error));
    }, []);

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [events, setEvents] = useState<any>([]);
    const [data, setData] = useState<any>([]);

    const handleEventViewer = (evt: string) => {
        fetch(`/api/get_comp_data/${evt}`, {
            method: "POST",
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            setData(data);
            setSelectedView(views.MATCH_SCOUTING);
        })
        .catch((error) => console.error(error));
    };

    useEffect(() => {

    }, []);
    const handlePitScoutingView = () => {};
    const handleMatchScoutingView = () => {};
    const handleMatchScoutingInterfacesView = () => {};
    const handleComparisonView = () => {};

    const [selectedView, setSelectedView] = useState<string>(
        views.EVENT_VIEWER
    );

    return (
        <div>
            <div>
                <h1>Data</h1>
                <DataNavbar setSelectedView={setSelectedView} views={views} />

                <button
                    onClick={() => setShowPopup(true)}
                    style={{ marginTop: 20 }}
                >
                    Filter By
                </button>
            </div>
            <div>
                {selectedView === views.EVENT_VIEWER && (
                    <div>
                        <h1>Choose an Event</h1>
                        {events.map((evt: string) => (
                            <button
                                key={evt}
                                onClick={() => handleEventViewer(evt)}
                            >
                                {evt}
                            </button>
                        ))}
                    </div>
                )}
                {selectedView === views.PIT_SCOUTING && (
                    <div>
                        <h1>Pit Scouting</h1>
                        {data.pit_data.map((entry: any) => (
                            <Entry key={entry.recordID} {...entry} />
                        ))}
                    </div>
                )}
                {selectedView === views.MATCH_SCOUTING && (
                    <div>
                        <h1>Match Scouting</h1>
                        {data.match_data.map((entry: any) => (
                            <Entry key={entry.recordID} {...entry} />
                        ))}
                    </div>
                )}
                {selectedView === views.MATCH_SCOUTING_INTERFACE && (
                    <div>
                        <h1>Match Scouting Interface</h1>
                    </div>
                )}
                {selectedView === views.COMPARISON && (
                    <div>
                        <h1>Comparison</h1>
                    </div>
                )}
            </div>

            <Popup trigger={showPopup} setTrigger={setShowPopup}>
                <h3>Popup</h3>
                <p>This is a popup</p>
            </Popup>
        </div>
    );
};

export default DataView;
