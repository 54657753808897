import React, { useState, useContext, useEffect } from "react";
import { MdOutlineSchedule } from "react-icons/md";
import { UserContext } from "../App";
import Card from "../components/Card";
import { FiDownloadCloud } from "react-icons/fi";
import { FaPlaneArrival } from "react-icons/fa";

const NewHome = () => {
    const [requestStatus, setRequestStatus] = useState<string>('******************');
    const [competition, setCompetition] = useState({ name: "Loading..." });
    const user = useContext(UserContext);

    useEffect(() => {
        // Fetch competition data when the component mounts
        fetch("/api/competition")
            .then((response) => response.json())
            .then((data) => setCompetition(data))
            .catch((error) =>
                console.error("Error fetching competition:", error)
            );
    }, []);

    const handleRequestTeams = () => {
        // Update request status to indicate the request is in progress
        setRequestStatus("Requesting teams...");

        // Make a request to the Flask backend to fetch data from Blue Alliance
        fetch("/api/request_data", {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the response data as needed
                console.log(data);
                // Update request status based on the response
                setRequestStatus(
                    data.success
                        ? "Failed to request teams"
                        : "Teams successfully requested!"
                );
            })
            .catch((error) => {
                console.error("Error requesting data:", error);
                // Update request status in case of an error
                setRequestStatus("Error requesting teams");
            });
    };

    const handleBlueAllianceSync = () => {
        setRequestStatus("Syncing Team Info with Blue Alliance...");
        fetch("/sync_to_blue_alliance", {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setRequestStatus(
                    data.success
                        ? "Data synced successfully!"
                        : "Failed to sync to Blue Alliance"
                );
            })
            .catch((error) => {
                console.error("Error syncing data:", error);
                // Update request status in case of an error
                setRequestStatus("Error syncing data");
            });
    };

    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <h1>Home</h1>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90vh",
                }}
            >
                <Card
                    title="Current Competition"
                    description={competition.name}
                    icon={<FaPlaneArrival />}
                />
                {user.role === "admin" && (
                    <>
                        <Card
                            title="Refresh Teams"
                            description="Download the latest team data"
                            icon={<FiDownloadCloud />}
                            onClick={handleRequestTeams}
                        />
                        <Card
                            title={requestStatus}
                            description=""
                            icon={<MdOutlineSchedule />}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default NewHome;
