import React from 'react';
import '../styles/Card.css';

interface CardProps {
    icon: React.ReactNode;
    title: string;
    description: string;
    onClick?: () => void;
}

const Card: React.FC<CardProps> = ({ icon, title, description, onClick }) => {
    return (
        <div className="card" onClick={onClick}>
            <div className="card-icon">{icon}</div>
            <div className="card-title">{title}</div>
            <div className="card-description">{description}</div>
        </div>
    );
};

export default Card;
