import React, { useState, useEffect, useRef } from 'react';
import RadioGroup from './RadioGroup';
import Counter from './Counter';
import RadioGroupMultiple from './RadioGroupMultiple';
import TextField from './TextField';
import Popup from '../Popup';

// Query interfaces
interface Option {
    id: number;
    name: string;
    value: string;
}

interface RadioGroupProps {
    options?: Option[];
    onChange: (data: any) => void;
}

interface CounterProps {
    min?: number;
    max?: number;
    onChange: (data: any) => void;
}

interface RadioGroupMultipleProps {
    options?: Option[];
    onChange: (data: any) => void;
}

interface TextFieldProps {
    placeholder?: string;
    onChange: (data: any) => void;
}

// Type for queries
interface QueryBase {
    id: number;
    type: string;
    key: string;
}

interface TextFieldQuery extends QueryBase {
    type: 'text';
    placeholder?: string;
}

interface RadioGroupQuery extends QueryBase {
    type: 'checkbox-group';
    options?: Option[];
}

interface CounterQuery extends QueryBase {
    type: 'counter';
    min?: number;
    max?: number;
}

interface RadioGroupMultipleQuery extends QueryBase {
    type: 'checkbox-group-multiple';
    options?: Option[];
}

type Query = TextFieldQuery | RadioGroupQuery | CounterQuery | RadioGroupMultipleQuery;

interface SectionProps {
    onChange: (index: string, data: any) => void;
}

const Section: React.FC<SectionProps> = ({ onChange }) => {
    const [title, setTitle] = useState('');
    const [queries, setQueries] = useState<Query[]>([]);
    const [showFormItems, setShowFormItems] = useState(false);

    const items: { id: number; name: string; type: Query["type"] }[] = [
        { id: 1, name: "Radio Group", type: 'checkbox-group' },
        { id: 2, name: "Counter", type: 'counter' },
        { id: 3, name: "Radio Group Multiple", type: 'checkbox-group-multiple' },
        { id: 4, name: "Text Field", type: 'text' },
    ];

    const toCamelCase = (str: string): string => {
        return str
            .toLowerCase()
            .replace(/(?:^\w|[A-Z]|\b\w|\s+|_|-)/g, (match, index) =>
                index === 0 ? match.toLowerCase() : match.toUpperCase()
            )
            .replace(/\s+|_|-/g, '');
    };

    const prevTitleRef = useRef(title);
    useEffect(() => {
        if (prevTitleRef.current !== title) {
            onChange("title", title);
            prevTitleRef.current = title;
        }
    }, [title, onChange]);

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleAddElement = (id: number, type: Query["type"]) => {
        const camelCaseTitle = toCamelCase(title); // Convert title to camel case
        const newElement: Query = { id, type, key: `q_${camelCaseTitle}` }; // Use camel case title as key
        setQueries([...queries, newElement]);
    };

    const handleElementChange = (index: number, data: any) => {
        const updatedElements = [...queries];
        updatedElements[index] = { ...updatedElements[index], ...data };
        // If title changes, update the key to match the new camel case title
        if (data.title) {
            const newKey = toCamelCase(data.title);
            updatedElements[index] = { ...updatedElements[index], key: `q_${newKey}` };
        }
        setQueries(updatedElements);
        onChange("queries", updatedElements);
    };

    return (
        <div>
            <h1>Title</h1>
            <input
                type="text"
                value={title}
                placeholder="Title"
                onChange={handleTitleChange}
            />

            <h2>Add Form Query</h2>
            <button onClick={() => setShowFormItems(true)}>Add Element</button>

            <h2>Queries</h2>
            {queries.map((element, index) => {
                switch (element.type) {
                    case 'checkbox-group':
                        return (
                            <RadioGroup
                                key={index}
                                options={element.options}
                                onChange={(data: any) => handleElementChange(index, data)}
                            />
                        );
                    case 'counter':
                        return (
                            <Counter
                                key={index}
                                min={element.min}
                                max={element.max}
                                onChange={(data: any) => handleElementChange(index, data)}
                            />
                        );
                    case 'checkbox-group-multiple':
                        return (
                            <RadioGroupMultiple
                                key={index}
                                options={element.options}
                                onChange={(data: any) => handleElementChange(index, data)}
                            />
                        );
                    case 'text':
                        return (
                            <TextField
                                key={index}
                                placeholder={element.placeholder}
                                onChange={(data: any) => handleElementChange(index, data)}
                            />
                        );
                    default:
                        return null;
                }
            })}

            <Popup trigger={showFormItems} setTrigger={setShowFormItems}>
                <h2>Add Form Element</h2>
                <div className="form-items">
                    {items.map((item) => (
                        <button key={item.id} onClick={() => handleAddElement(item.id, item.type)}>
                            {item.name}
                        </button>
                    ))}
                </div>
            </Popup>
        </div>
    );
};

export default Section;
