import { Link } from "react-router-dom";
import { SidebarDataAdmin, SidebarDataScouter, SidebarDataBase } from "../SidebarData";
import { FiUsers } from "react-icons/fi";
import React, {useContext} from "react";
import "../styles/Sidebar.css";
import { UserContext } from "../App";

interface SidebarProps {
    handleLogout: () => void;
}

const Sidebar = (props: SidebarProps) => {
    const user = useContext(UserContext);

    const getSideBarData = () => {
        if (user.role === "admin") {
            return SidebarDataAdmin;
        } else if (user.role === "scouter") {
            return SidebarDataScouter;
        } else if (user.role === "base") {
            return SidebarDataBase;
        }      
        
        return null;
    }



    return (
        <div className="sidebar">
            <ul className="sidebar-list">
                <div id="logo">
                    <img style={{height: 50, width: 50, borderRadius: 10}} src="https://pbs.twimg.com/profile_images/972671431795527680/vHSYqWNA_400x400.jpg" alt="logo" />
                    <h1 style={{marginTop: 0, margin: 0, alignSelf: "center", marginLeft: 20}}>Scout Server</h1>
                </div>
                {getSideBarData()?.map((item, index) => {
                    return (
                        <li key={index} className="row" id={window.location.pathname == item.redirect ? "active" : "none"}>
                            <div id="icon">{item.icon}</div>{' '}
                            <Link id="title" to={item.redirect}>{item.title}</Link>
                        </li>
                    );
                })}
            </ul>
            <button className="user" onClick={props.handleLogout}>
                <div id="icon">{<FiUsers />}</div>{' '}
                <h2 id='title'>{user.name}</h2>
            </button>
        </div>
    );
};

export default Sidebar;
