import React from "react";

interface CrescendoProps {
    recordID: string;
    timeOfCreation: string;
    teamNumber: number;
    matchNumber: number;
    matchType: string;
    alliance: string;
    preloaded: boolean;
    driveStation: string;
    robotLeft: boolean;
    autonSpeakerNotesScored: number;
    autonMissed: number;
    autonNotesReceived: number;
    autonIssues: string[]; // not moving, stopped, out of control
    telopSpeakerNotesScored: number;
    telopAmpNotesScored: number;
    telopSpeakerNotesMissed: number;
    telopAmpNotesMissed: number;
    droppedNotes: number;
    telopNotesReceivedFromHumanPlayer: number;
    telopNotesReceivedFromGround: number;
    ferryNotes: number;
    endGame: string;
    trap: number;
    teleopIssues: string[]; // not moving, lost connection, fms issues, disabled
    fouls: number;
    techFouls: number;
    yellowCards: number;
    redCards: number;
    didTeamPlayDefense: boolean;
    scouterName: string;
}

const Entry: React.FC<CrescendoProps> = (props) => {
    return (
        <div>
            <h1>{props.recordID}</h1>
            <table>
                <thead>
                    <tr>
                        <th>Field</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(props).map(([key, value]) => (
                        <tr key={key}>
                            <td>{key}</td>
                            <td>
                                {Array.isArray(value) && value.length === 0
                                    ? "None"
                                    : typeof value === "boolean"
                                    ? value
                                        ? "Yes"
                                        : "No"
                                    : value}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Entry;
