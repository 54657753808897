import React, { useState, useEffect, useRef } from "react";

interface TextFieldProps {
    title?: string;
    placeholder?: string;
    onChange: (data: { title: string; placeholder: string }) => void;
}

const TextField: React.FC<TextFieldProps> = ({ title = "", placeholder = "", onChange }) => {
    const [localTitle, setLocalTitle] = useState<string>(title);
    const [localPlaceholder, setLocalPlaceholder] = useState<string>(placeholder);

    // Use refs to track previous values
    const prevTitleRef = useRef(localTitle);
    const prevPlaceholderRef = useRef(localPlaceholder);

    useEffect(() => {
        // Only call onChange if the values actually changed
        if (prevTitleRef.current !== localTitle || prevPlaceholderRef.current !== localPlaceholder) {
            onChange({ title: localTitle, placeholder: localPlaceholder });
            prevTitleRef.current = localTitle;
            prevPlaceholderRef.current = localPlaceholder;
        }
    }, [localTitle, localPlaceholder, onChange]);

    return (
        <div>
            <div>
                <h1>Text Field</h1>
                <label style={{ color: 'white' }} htmlFor="title">Title:</label>
                <input
                    type="text"
                    id="title"
                    value={localTitle}
                    onChange={(e) => setLocalTitle(e.target.value)}
                />
            </div>
            <div>
                <label style={{ color: 'white' }} htmlFor="placeholder">Placeholder:</label>
                <input
                    type="text"
                    id="placeholder"
                    value={localPlaceholder}
                    onChange={(e) => setLocalPlaceholder(e.target.value)}
                />
            </div>
        </div>
    );
};

export default TextField;
