import React, { useState } from "react";
import Popup from "../components/Popup.tsx";
import "../styles/Login.css";
import supabase from "../auth.ts";

const appVersion = "WEB-1.0.0";

const Login = ({ setUser }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const {data, error} = await supabase.auth.signInWithPassword({
                email: username,
                password: password,
            });
            
            const user = {
                "id": data.sub,
                "name": data.name,
                "username": data.username,
                "osis": data.osis,
                "email": data.email,
                "role": data.role,
            }
            setUser(user);

            if (error) {
                setPopupMessage("Invalid username or password");
                setShowPopup(true);
                console.log("Login Failed");
                return;
            }
        } catch (error) {
            console.error("Error logging in:", error);
        }
    };

    return (
        <div
            style={{
                display: "flex",
                height: "100vh",
            }}>
            <div className='login-container'>
                <h1>Welcome Back</h1>
                <p>Sign in to your account</p>
                <div className="divider"></div>
                <form method="post" className="" onSubmit={handleLogin}>
                    <label htmlFor="username" className="label" >Username:</label>
                    <input
                        type="text"
                        id="username"
                        className="login-input"
                        name="username"
                        value={username}
                        onChange={handleUsernameChange}
                        required
                    />
                    <label htmlFor="password" className="label">Password:</label>
                    <input
                        type="password"
                        id="password"
                        className="login-input"
                        name="password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                    />
                    <button type="submit">Login</button>
                </form>
                <h3>or</h3>
                <button
                    onClick={() => {
                        console.log('create account');
                    }}>
                    Create Account
                </button>
                <p>Version: {appVersion}</p>
                <p style={{marginTop: 0}}>© 2024 Steel Hawks Robotics Inc.</p>
                <Popup trigger={showPopup} setTrigger={setShowPopup}>
                    <h1>{popupMessage}</h1>
                </Popup>
            </div>
            <div className="image-container">
                <img src="https://pbs.twimg.com/profile_images/972671431795527680/vHSYqWNA_400x400.jpg" style={{borderRadius: 20, alignSelf: 'center'}}/>
            </div>
        </div>
    );
};

export default Login;
